import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-game-infos',
  templateUrl: './game-infos.component.html',
  styleUrls: ['./game-infos.component.css']
})
export class GameInfosComponent implements OnInit {
  @Input() score: number;
  @Output() newGame = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public onNewGame(): void {
    this.newGame.emit();
  }
}
