export class Suit {
  symbol: string;
  code: string;
  color: string;
  constructor(values: any) {
    this.symbol = values.symbol;
    this.code = values.code;
    this.color = values.color;
  }
}

export const suits: Suit[] = [
  new Suit({
    symbol: '♥',
    code: 'heart',
    color: 'red'
  }),
  new Suit({
    symbol: '♦',
    code: 'diamond',
    color: 'red'
  }),
  new Suit({
    symbol: '♠',
    code: 'spade',
    color: 'black'
  }),
  new Suit({
    symbol: '♣',
    code: 'club',
    color: 'black'
  })
];

export class Card {
  hidden = true;
  dragging = false;
  x = 0;
  y = 0;
  private value: number;

  constructor(value: any) {
    if (typeof value === 'number') {
      this.value = value;
    } else if (typeof value === 'object') {
      this.value = value.value;
      this.hidden = value.hidden;
    }
  }

  public simplify(): any {
    return { value: this.value, hidden: this.hidden };
  }

  public get cardNumericValue(): number {
    const numericValue = this.value % 13;
    return numericValue;
  }

  public get cardValue(): string {
    const numericValue = this.value % 13;
    if (numericValue > 9) {
      return ['J', 'Q', 'K'][numericValue - 10];
    } else if (numericValue === 0) {
      return 'A';
    } else {
      return (numericValue + 1).toString();
    }
  }

  public get suit(): Suit {
    return suits[Math.floor(this.value / 13)];
  }
}
