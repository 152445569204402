import { Component, HostListener, OnInit } from '@angular/core';
import { PersistenceService, StorageType } from 'angular-persistence';

import { Card, Suit, suits } from '../models/card';
import { Column } from '../models/column';
import { Stock } from '../models/stock';
import { CardsService } from '../services/cards.service';
import { Foundation } from '../models/foundation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {
  draggingCards: Card[] = [];
  baseX = 0;
  baseY = 0;

  columns: Column[];
  foundations: Foundation[];
  foundationCompletionSubscriptions: Subscription[];
  foundationSuitsCompleted: Suit[];
  stock: Stock;
  score = 0;

  innerHeight: number;
  innerWidth: number;

  constructor(private cardsService: CardsService, private persistenceService: PersistenceService) {
    this.reshuffleCards(true);
  }

  ngOnInit() {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
  }

  private reshuffleCards(restore: boolean) {
    if (restore) {
      this.cardsService.generateCards(this.persistenceService.get('cards', StorageType.SESSION));
    } else {
      this.cardsService.generateCards(null);
    }
    this.columns = this.cardsService.columns;
    this.stock = this.cardsService.stock;

    this.foundationSuitsCompleted = new Array<Suit>();
    if (this.foundationCompletionSubscriptions !== undefined) {
      this.foundationCompletionSubscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }
    this.foundations = this.cardsService.foundations;
    this.foundationCompletionSubscriptions
      = this.foundations.map(x => x.completed.subscribe((suit: Suit) => this.onFoundationComplete(suit)));

    this.saveCards();
  }

  private onFoundationComplete(suit: Suit) {
    this.foundationSuitsCompleted.push(suit);
    if (this.foundationSuitsCompleted.length === suits.length) {
      // TODO: Congratulate the user!
      console.log('Complete!');
      this.saveCards();
      // TODO: Ask user if they'd like to play a new game
    }
  }

  private saveCards(): void {
    this.persistenceService.set('cards', this.cardsService.simplify(), { type: StorageType.SESSION });
  }

  @HostListener('window:resize', [])
  onResize() {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: any) {
    this.onMove(event.clientX, event.clientY);
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: any) {
    if (event.touches.length > 1) {
      return false;
    }
    event.preventDefault();
    const touch = event.changedTouches[0];
    this.onMove(touch.clientX, touch.clientY);
  }

  private onMove(xEvent: number, yEvent: number) {
    if (this.draggingCards.length) {
      const x = xEvent - this.baseX;
      const y = yEvent - this.baseY;
      this.draggingCards.forEach(card => {
        card.x = x;
        card.y = y;
      });
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: any) {
    if (event.touches.length > 0) {
      return false;
    }
    const touch = event.changedTouches[0];
    this.onRelease(touch.clientX, touch.clientY);
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: any) {
    this.onRelease(event.clientX, event.clientY);
  }

  private onRelease(xEvent: number, yEvent: number) {
    if (this.draggingCards.length) {
      if (yEvent > this.innerHeight / 100 * 23) {
        const selectedColumn = this.columns[Math.floor(xEvent / (this.innerWidth / this.cardsService.COLUMNS_NUMBER))];
        this.cardsService.tryToMove(this.draggingCards, selectedColumn);
      } else if (this.draggingCards.length === 1) {
        this.cardsService.tryToMoveFoundation(this.draggingCards);
      }
      this.resetDraggingCards();
    }
  }

  private resetDraggingCards() {
    this.draggingCards.forEach(card => {
      card.x = 0;
      card.y = 0;
      card.dragging = false;
    });
    this.draggingCards = [];
    this.saveCards();
  }

  public onCardSelected(event: any) {
    if (this.draggingCards.length) {
      return false;
    }

    if (event.cards.find((card: Card) => card.hidden)) {
      return false;
    }

    this.baseX = event.x;
    this.baseY = event.y;
    this.draggingCards = event.cards;
    this.draggingCards.forEach(card => {
      card.dragging = true;
    });
  }

  public onCardClicked(event: any) {
    if (event.cards.find((card: Card) => card.hidden)) {
      return;
    }

    const cards = event.cards;
    this.cardsService.tryToGuessMove(cards);
  }

  public onStockClicked() {
    this.cardsService.getFromStock();
    this.saveCards();
  }

  public onResetStock() {
    this.cardsService.resetStock();
    this.saveCards();
  }

  public onNewGame() {
    this.reshuffleCards(false);
  }
}
